
































import { Vue, Component, Emit } from "vue-property-decorator";
@Component
export default class TermsConditions extends Vue {
  public isShow: boolean = true;
  @Emit("close")
  close() {
    this.isShow = false;
    return this.isShow;
  }
}

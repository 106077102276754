export enum Enums {
  Admin = 1
}
export enum DayOfWeek {
  Saturday,
  Sunday,
  Monday,
  Tuesday,
  wednesday,
  Thusday,
  Friday,
}

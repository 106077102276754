






























































































































































import { Vue, Component, Prop, Watch, Emit } from "vue-property-decorator";
import { LoginViewModel } from "../../website/models/LoginViewModel";
import AuthService from "../../website/services/AuthService";
import AuthUser from "../../global-config/AuthUser";
import { RegViewModel } from "../../website/models/RegViewModel";
import AlertService from "../../common-app/service/AlertService";
@Component
export default class GuestLogin extends Vue {
  public isShow: boolean = true;
  public submitted: boolean = false;
  public user: LoginViewModel = {} as LoginViewModel;
  public contAsGuest: boolean = false;
  public verifyGuest: boolean = false;
  public guestVerified: boolean = false;
  public userReg: RegViewModel = {} as RegViewModel;
  public code: string = "";
  public password: string = "";
  public userInfo: any;
  public isResend:boolean=false;
  @Emit("close")
  close() {
    this.isShow = false;
    return this.isShow;
  }
  @Emit("guestLoggedIn")
  verficationDone() {
    this.isShow = false;
    this.guestVerified = true;
    //alert(this.$store.getters.userId);
    return this.guestVerified;
  }
  created() {
    const dict = {
      custom: {
        phone: {
          required: "Phone number cannot be empty",
          numeric: "Phone number can contain only numeric charecters",
          min: "The phone number must be at least 11 charecters",
          max: "The phone number cannot be more than 11 charecters",
        },
        guestPhone: {
          required: "Phone number cannot be empty",
          numeric: "Phone number can contain only numeric charecters",
          min: "The phone number must be at least 11 charecters",
          max: "The phone number cannot be more than 11 charecters",
        },
        fullName: {
          required: "Full name is required.",
        },
        password: {
          required: "password is required.",
        },
        Guestpass: {
          required: "password is required.",
          min: "The password must be at least 6 charecters",
        },
      },
    };
    this.$validator.localize("en", dict);
  }
  onSubmit() {
    this.$validator.validate().then((valid) => {
      if (valid) {
        this.submitted = true;
        AuthService.login(this.user)
          .then((response) => {
            if (response.data.isAuthorized) {
              AuthUser.setToken(response.data);
              //this.$router.push({ path: "#" });
              //location.reload();
              this.close();
            } else {
              this.submitted = false;
              AlertService.sendMessage(response.data.message);
            }
          })
          .catch((error) => {
            this.submitted = false;
          });
      }
    });
  }
  send() {
    this.$validator.validate().then((valid) => {
      if (valid) {
        this.submitted = true;
        if (this.$store.getters.verifycode === this.code) {
          AuthService.verify({
            userId: this.$store.getters.userId,
            code: this.code,
          })
            .then((response) => {
              if (response.data) {
                this.$store.dispatch("addVerify", {
                  userId: this.$store.getters.userId,
                  verifycode: this.code,
                });
                AuthUser.setToken(response.data);
                // alert(this.$store.getters.userId);
                AuthService.setPasswordGuest(this.password, { userId: this.$store.getters.userId }).then((res: any) => {
                  if (res.data.status) {
                    //return;
                  }
                });
                this.close();
                //this.$router.push({ path: "registration-confirmation" });
                this.verficationDone();
                this.guestVerified = true;
              } else {
                this.submitted = false;
                AlertService.sendMessage({ status: false, message: "The verification code is not valid" });
              }
            })
            .catch((dd) => {
              var sf = "";
            });
        } else {
          this.submitted = false;
          AlertService.sendMessage({ status: false, message: "The verification code is not valid" });
        }
      }
    });
  }
  onSubmitNext() {
    this.userReg.password = "1234567";
    this.$validator.validate().then((valid) => {
      if (valid) {
        this.submitted = true;
        AuthService.registration(this.userReg)
          .then((response) => {
            let result = response.data as any;
            if (result.status) {
              // console.log(result.result.securityVerification.code);
              this.$store.dispatch("addVerify", {
                userId: result.result.user.id,
                verifycode: result.result.securityVerification.code,
              });
              //this.$router.push({ path: "registration-verify" });
              this.verifyGuestInfo();
              // this.setPassword(result.result.user.id);
            } else {
              AlertService.sendMessage(result.message);
              //this.submitted = false;
              if (result.messageType == "unique") {
                this.$validator.errors.add({
                  field: "phone",
                  msg: result.message,
                });
              } else {
                AlertService.sendMessage(result.message);
              }
            }
          })
          .catch((error) => {
            // this.submitted = false;
          });
      }
    });
  }
  continueAsGuest() {
    this.contAsGuest = true;
  }
  verifyGuestInfo() {
    this.verifyGuest = true;
  }

  setPassword(userId: number) {
    AuthService.setPasswordGuest(this.password, this.userInfo).then((res: any) => {
      if (res.data.status) {
        //return;
      }
    });
  }

  ReSend() {
    this.isResend=true;
    AuthService.ReSendCode({
      userId: this.$store.getters.userId,
      code: this.$store.getters.verifycode,
    }).then((response) => {
      if (response.data.status) {
        this.$store.dispatch("addVerify", {
          userId: this.$store.getters.userId,
          verifycode: response.data.result,
        });
        AlertService.sendMessage(response.data);
            this.isResend=false;
      } else {
        AlertService.sendMessage(response.data);
            this.isResend=false;
      }
    });
  }

  // verficationDone() {
  //   this.guestVerified = true;
  //   alert(this.$store.getters.userId);
  // }
}
